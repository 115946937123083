import { useEffect } from "react";
import { useMutation } from "react-query";
import { useParams, useHistory } from "react-router";
import { useUpdateActiveStepRequest } from "../../modules/network";
import { useUserModuleDispatch, USER_ACTION } from "../../modules/user";
import { getGameRoute } from "../routes";

const TestStep = () => {

    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const dispatch = useUserModuleDispatch();

    const request = useUpdateActiveStepRequest();

    const { mutate } = useMutation(request, {
        onSuccess: () => {
            dispatch({
                type: USER_ACTION.SET_ACTIVE_STEP,
                payload: {
                    id
                }
            })
            history.replace(getGameRoute());
        }
    });

    useEffect(() => {
        mutate(id)
    }, [mutate, id]);

    return <></>
}

export default TestStep;
