import React, { useMemo } from "react"
import { GameStep } from "../../modules/game"
import StepVideo from "../video-step"
import './style.scss';

type Props = {
    step: GameStep,
}

const StepTemplate: React.FC<Props> = ({children, step}) => {
    const imgSrc = useMemo(() => {
        return `url(${step.image}?random=${Math.random()})`
    }, [step])

    return <div className="step-template">
        <div className="image-column">
            {
                step.video && <StepVideo image={step.image} video={step.video} />
            }
            {
                !step.video && <div className="img" style={{
                    backgroundImage: imgSrc
                }}></div>
            }

        </div>
        <div className="content-column">
            {children}
        </div>
    </div>
}

export default StepTemplate;
