import { useQuery } from "react-query";
import { useGetGameRunRequest } from "../../network";

export default function useGameRun(id: number) {

    const getGameRun = useGetGameRunRequest()

    const {data, isFetching, error } = useQuery(['get-run', id], () => {
        return getGameRun(id)
    }, {
        staleTime: Infinity
    })

    return {
        isLoading: isFetching,
        gameRun: data,
        error
    }

}
