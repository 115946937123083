import { useCallback, useEffect } from "react";
import { MAIN_MODULE_ACTION, useMainModuleDispatch, useMainModuleState } from ".."

export default function useFontSizeMode() {

    const {fontSize} = useMainModuleState();
    const dispatch = useMainModuleDispatch();

    const setFontSize = useCallback((value: number) => {
        dispatch({
            type: MAIN_MODULE_ACTION.SET_FONT_SIZE,
            payload: {
                value
            }
        })
    }, [dispatch])

    useEffect(() => {
        const html = document.documentElement;
        html.style.fontSize = `${18*fontSize}px`;

        return () => {html.style.fontSize = ''};

    }, [fontSize]);

    return {
        setFontSize
    }
}
