import React from "react";
import { Link } from "react-router-dom";
import useClasses from "../../modules/teacher/hooks/use-classes";
import { getClassPanelRoute } from "../../routes/routes";
import ErrorInfo from "../error";
import Loader from "../loader";

type Props = {

}

const ClassesList: React.FC<Props> = () => {

    const { classesList, isLoading, error } = useClasses();

    return <div className="classes-list">
        <div className="content">
            {isLoading && <Loader />}
            {!isLoading && <>
                {error && !isLoading && <ErrorInfo />}
                {!error && <>
                    <h2 className="heading-decor">Lista klas</h2>
                    <ol>
                        {classesList.map(c => {
                            return <li key={c.id}>
                                <Link to={getClassPanelRoute(c.id)}>{c.name}</Link>
                            </li>
                        })}
                    </ol>
                </>}
            </>}
        </div>
    </div>
}

export default ClassesList;
