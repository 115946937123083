import React, { useRef, useCallback, useEffect, useMemo } from "react";
import AudioPlayer from "../../components/audio-player";
import { StepAction } from "../../modules/game";

type Props = {
    action?: StepAction,
    text?: string,
    audio?: {
        ogg?: string,
        m4a?: string
    },
    onPopupOk(): void
}

const Popup: React.FC<Props> = ({ text, audio, action, onPopupOk }) => {

    const textRef = useRef<HTMLDivElement>(null);
    const playerButtonRef = useRef<HTMLButtonElement>(null);
    const nextButtonRef = useRef<HTMLButtonElement>(null);

    const firstFocusListHandler = useCallback((e: KeyboardEvent) => {
        if (nextButtonRef.current && e.key === 'Tab' && e.shiftKey === true) {
            e.preventDefault();
            nextButtonRef.current.focus();
        }
    }, []);

    const lastFocusListHandler = useCallback((e: KeyboardEvent) => {
        if (textRef.current && e.key === 'Tab' && e.shiftKey === false) {
            e.preventDefault();
            textRef.current.focus();
        }
    }, []);

    const showAudioPlayer = useMemo(() => {
        return audio && (audio.ogg || audio.m4a);
    }, [audio])

    useEffect(() => {
        nextButtonRef.current?.focus();
    }, [])

    useEffect(() => {

        const nextButton = nextButtonRef.current
        const text = textRef.current

        if (nextButton && text) {
            nextButton.addEventListener('keydown', lastFocusListHandler)
            text.addEventListener('keydown', firstFocusListHandler)
        }

        return () => {
            nextButton?.removeEventListener('keydown', lastFocusListHandler)
            text?.removeEventListener('keydown', firstFocusListHandler)
        }
    }, [firstFocusListHandler, lastFocusListHandler])

    return <div className="popup" role="dialog" aria-modal="true" aria-describedby="popup-content">
        <div className="container">
            <div className="wrapper">
                <div className="scroll-box">
                    <div id="popup-content">
                        {text && <div ref={textRef} className="content" tabIndex={-1}>
                            <p>{text}</p>
                        </div>}
                        <div className="points">
                            {action?.points?.catA && <p className="points-1"><strong>{action?.points.catA > 0 ? '+' : ''}{action?.points.catA}</strong> Atmosfera</p>}
                            {action?.points?.catP && <p className="points-2"><strong>{action?.points.catP > 0 ? '+' : ''}{action?.points.catP}</strong> Profesjonalizm</p>}
                            {action?.points?.catZ && <p className="points-3"><strong>{action?.points.catZ > 0 ? '+' : ''}{action?.points.catZ}</strong> Liczba Zabytków</p>}
                        </div>
                    </div>
                    {showAudioPlayer && audio && <AudioPlayer src={audio} buttonRef={playerButtonRef} />}
                    <div>
                        <div className="buttons">
                            <button className="button" ref={nextButtonRef} onClick={onPopupOk}>Dalej</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default Popup;
