import { initUserState, UserModuleAction, UserModuleState, USER_ACTION } from ".";

export default function UserReducer(state: UserModuleState, action: UserModuleAction): UserModuleState {

    const { type, payload } = action;

    switch (type) {

        case USER_ACTION.SET_ACTIVE_STEP:
            return {
                ...state,
                activeStep: payload.id
            }


        case USER_ACTION.LOGIN:
            return {
                ...state,
                id: payload.id,
                role: payload.role
            }

        case USER_ACTION.LOGOUT:
            return { ...initUserState };

        case USER_ACTION.FETCH_SUCCESS:
            return {
                ...state,
                ...payload
            }
    }

    return state;
}
