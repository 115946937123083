import { useCallback } from "react";
import { GAME_RUN_ACTION, useGameRunModuleDispatch } from "..";

export default function useUserChoice() {
    const dispatch = useGameRunModuleDispatch();

    return useCallback((stepId: string, value: number) => {
        dispatch({
            type: GAME_RUN_ACTION.SET_CHOICE,
            payload: {
                stepId,
                value
            }
        })
    }, [dispatch])
}
