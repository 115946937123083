import React from "react";
import { CONFIG } from "../../config";
import AzureForLogged from "../../modules/azure/for-logged";
import HostingForLogged from "../../modules/hosting/for-logged";

type Props = {

}

const ForLogged: React.FC<Props> = ({children}) => {
    const isAzureHosting = CONFIG.HOSTING_TYPE === 'azure';

    return <>
        {isAzureHosting && <AzureForLogged>{children}</AzureForLogged>}
        {!isAzureHosting && <HostingForLogged>{children}</HostingForLogged>}
    </>
}

export default ForLogged;
