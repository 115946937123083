import { useEffect, useRef, useState } from "react";
import SummaryTab from "../../components/summary-tab";
import useUser from "../../modules/user/hooks/use-user";

import './style.scss';

export default function UserSummaryPage() {

    const { user } = useUser();

    const header = useRef<HTMLHeadingElement>(null);

    const firstRunExists = Boolean(user.firstRun);
    const lastRunExists = Boolean(user.lastRun);

    const anyRunExists = firstRunExists || lastRunExists;

    const [selected, setSelected] = useState<number | null>(user.firstRun || null);

    useEffect(() => {
        header.current?.focus();
    }, [])

    return <div className="user-summary-page">
        <header>
            <div className="summary-top-gfx"></div>
            <h1 ref={header} tabIndex={-1} className="heading-1">Podsumowanie</h1>
        </header>
        <main>
            {!anyRunExists && <p>Najpier musisz przejśc grę</p>}
            {anyRunExists && <>

                <section className="tab-container">
                    <div className="tabs" role="radiogroup">
                        {firstRunExists && <div className="tab">
                            <button
                                role="radio"
                                aria-checked={selected === user.firstRun}
                                onClick={() => { setSelected(user.firstRun || null) }}
                                className={selected === user.firstRun ? 'selected' : ''}
                            >
                                Pierwsza rozgrywka
                    </button>
                        </div>}
                        {lastRunExists && <div className="tab">
                            <button
                                role="radio"
                                aria-checked={selected === user.lastRun}
                                onClick={() => { setSelected(user.lastRun || null) }}
                                className={selected === user.lastRun ? 'selected' : ''}
                            >
                                Ostatnia rozgrywka
                    </button>
                        </div>}
                    </div>
                </section>
                {selected && <SummaryTab id={selected} />}
            </>}
        </main>
    </div>
}

