import React from "react";
import useIsLogged from "../user/hooks/use-is-logged";

type Props = {

}

const HostingForLogged: React.FC<Props> = ({ children }) => {
    const isLogged = useIsLogged();

    return <>
        {isLogged && <>{ children }</>}
    </>
}

export default HostingForLogged;
