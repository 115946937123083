import { useMemo } from "react";
import { useQuery } from "react-query";
import useGame from "../../game/hooks/use-game";
import { useClassRaportRequest } from "../../network";

export type ClassResult = {
    id: string,
    globalVotes: number,
    classVotes: number,
    actions: {
        value: number,
        globalVotes: number,
        classVotes: number
    }[]
}

export default function useClassRaport(id: string) {
    const {game} = useGame();

    const stepsWithChoices = useMemo(() => {
        const steps = game.steps;
        const choicesID = game.summary.order;

        return steps.filter(s => choicesID.includes(s.id));

    }, [game])

    const request = useClassRaportRequest(id);

    const {data, isFetching, error} = useQuery('class-raport', request, {
        staleTime: Infinity
    });

    return {
        steps: stepsWithChoices,
        results: data as ClassResult[],
        isLoading: isFetching,
        error
    }
}
