import { useState } from "react";
import { useRef, useCallback, useEffect } from "react";

import './style.scss';

type Props = {
    src: {
        ogg?: string,
        m4a?: string
    },
    buttonRef?: React.RefObject<HTMLButtonElement>
}

const AudioPlayer: React.FC<Props> = ({ src, buttonRef }) => {


    const player = useRef<HTMLAudioElement>(null)
    const timelineRef = useRef<HTMLInputElement>(null);

    const [isPlaying, setIsPlaying] = useState<boolean>(false);

    const onPlayClicked = useCallback(() => {
        player.current?.play();
        setIsPlaying(true);
    }, [player, setIsPlaying])


    const onPauseClicked = useCallback(() => {
        player.current?.pause();
        setIsPlaying(false);
    }, [player, setIsPlaying]);


    const onToggle = useCallback(() => {
        if (isPlaying) {
            onPauseClicked();
        } else {
            onPlayClicked();
        }
    }, [isPlaying, onPlayClicked, onPauseClicked]);

    const onPlay = useCallback(() => { }, []);

    const onPause = useCallback(() => { }, []);

    const onEnded = useCallback(() => {
        setIsPlaying(false);
    }, [setIsPlaying]);

    const onTimeChange = useCallback(() => {
        if (timelineRef.current && player.current) {
            const value = parseInt(timelineRef.current.value);
            player.current.currentTime = (value / 100) * player.current.duration;
        }
    }, [timelineRef, player])

    useEffect(() => {
        if (player.current) {
            const playerEl = player.current;
            playerEl.addEventListener('play', onPlay)
            playerEl.addEventListener('pause', onPause)
            playerEl.addEventListener('ended', onEnded)

            return () => {
                if (playerEl) {
                    playerEl.pause()
                    playerEl.removeEventListener('play', onPlay)
                    playerEl.removeEventListener('pause', onPause)
                    playerEl.removeEventListener('ended', onEnded)
                }
            }
        }
    }, [onEnded, onPause, onPlay, player])

    useEffect(() => {

        if (timelineRef.current) {
            const timeline = timelineRef.current
            timeline.addEventListener('change', onTimeChange);

            return () => {
                if (timeline) {
                    timeline.removeEventListener('change', onTimeChange);
                }
            }
        }


    }, [timelineRef, player, onTimeChange])

    return (
        <div className="audio-player">
            <button
                ref={buttonRef}
                onClick={onToggle}
                className={isPlaying ? 'pause' : 'play'}
                role="switch"
                aria-checked={isPlaying ? 'true' : 'false'}
                aria-label="Odtwórz nagranie"
            >
                {isPlaying ? 'zapauzuj' : 'odtwórz'}
            </button>
            <audio ref={player}>
                <source src={src.m4a} type="audio/mp4" />
                <source src={src.ogg} type="audio/ogg" />
            </audio>
        </div>
    )
}

export default AudioPlayer;
