export function getHomeRoute() {
    return '/';
}

export function getGameRoute() {
    return '/game';
}

export function getSummaryRoute(id: number | string = ':id') {
    return `/summary/${id}`;
}

export function getClassListRoute(id: string = ':id') {
    return `/class-members/${id}`;
}

export function getRulesListRoute(id: string = ':id') {
    return `/rules/${id}`;
}

export function getClassRaportRoute(id: string = ':id') {
    return `/class-raport/${id}`;
}

export function getUserSummaryRoute() {
    return `/user-summary`;
}

export function getTeacherPanelRoute() {
    return '/teacher-panel';
}

export function getClassPanelRoute(id: string = ':id') {
    return `/class/${id}`;
}

export function getGameEndRoute() {
    return '/game/end';
}
