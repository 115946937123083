import Axios from 'axios';
import { useCallback } from 'react';
import createAxiosProxider from './network-provider';

type GetConfigResponse = {
    serverTime: number
}

const LS_PREFIX = 'demo-netykieta-';

function getConfig(): Promise<GetConfigResponse> {

    return Promise.resolve({
        serverTime: Date.now()
    });
}

function getGame(): Promise<any> {
    return Axios.get('/data.json')
        .then((response) => {
            return {
                steps: response.data.steps,
                startStep: response.data.startStep,
                summary: response.data.summary
            }
        })
}

function useGetGameRunRequest() {

    return useCallback((id: number) => {
        try {
            const runs = JSON.parse(localStorage.getItem(LS_PREFIX + 'runs') || 'null') || {};

            if (id === 0) {
                return {
                    choices: [
                        { key: 'pon1530', value: 1 },
                        { key: 'wt1000', value: 2 },
                        { key: 'wt1659', value: 3 },
                        { key: 'sr1400', value: 1 },
                        { key: 'pt1600', value: 2 },
                        { key: 'sr1800', value: 3 },
                        { key: 'pt1630', value: 1 },
                        { key: 'cz1530', value: 2 },
                        { key: 'cz2000', value: 3 },
                        { key: 'pt0800', value: 1 },
                        { key: 'sb1100', value: 2 },
                        { key: 'pn0855', value: 3 }
                    ],
                    rules: [
                        'Zaczynamy punktualnie, a o ewentualnych spóźnieniach informujemy wszystkich członków zespołu wcześniej.', 'O ile to możliwe, przez cały czas trwania spotkania mamy włączoną kamerkę, a mikrofon tylko wtedy, gdy chcemy coś powiedzieć.', 'Sprawy towarzyskie i rozmowy dotyczące innych przedmiotów i projektów omawiamy indywidualnie, a nie w czasie wspólnych spotkań.'
                    ]
                }
            }


            if (runs[`${id}`]) {
                return Promise.resolve(runs[`${id}`])
            } else {
                runs[`${id}`] = {
                    choices: [
                        { key: 'pon1530', value: null },
                        { key: 'wt1000', value: null },
                        { key: 'wt1659', value: null },
                        { key: 'sr1400', value: null },
                        { key: 'pt1600', value: null },
                        { key: 'sr1800', value: null },
                        { key: 'pt1630', value: null },
                        { key: 'cz1530', value: null },
                        { key: 'cz2000', value: null },
                        { key: 'pt0800', value: null },
                        { key: 'sb1100', value: null },
                        { key: 'pn0855', value: null }
                    ],
                    rules: [null, null, null]
                }

                localStorage.setItem(LS_PREFIX + 'runs', JSON.stringify(runs));

                return Promise.resolve(runs[`${id}`]);
            }

        } catch (e) {

        }
    }, []);
}

function useNewGameRunRequest() {

    return useCallback(() => {

        try {
            const user = JSON.parse(localStorage.getItem(LS_PREFIX + 'user') || 'null');

            const runID = Math.floor(Math.random() * 10000) + 1;

            user.activeRun = runID;
            user.activeStep = 'pon0855';

            localStorage.setItem(LS_PREFIX + 'user', JSON.stringify(user));

            return Promise.resolve({
                user,
                runID
            })
        } catch (e) {
            throw new Error();
        }
    }, []);
}

function useUpdateActiveStepRequest() {

    return useCallback((stepID: string) => {

        const user = JSON.parse(localStorage.getItem(LS_PREFIX + 'user') || 'null');

        user.activeStep = stepID;

        localStorage.setItem(LS_PREFIX + 'user', JSON.stringify(user));

        return Promise.resolve();

        // return axios.post('/user/active-step', {
        //     activeStep: stepID
        // })
    }, []);
}

function useLoginRequest() {

    return useCallback((params: { id: string, role: string }) => {

        localStorage.setItem(LS_PREFIX + 'user', JSON.stringify(params));

        return Promise.resolve();


    }, []);
}

function useLogoutRequest() {

    return useCallback(() => {
        localStorage.removeItem(LS_PREFIX + 'user');
        localStorage.removeItem(LS_PREFIX + 'runs');

        return Promise.resolve();
    }, []);
}

function useRegisterChoiceRequest() {

    return useCallback((params: { runID: number, key: string, value: number }) => {

        const runs = JSON.parse(localStorage.getItem(LS_PREFIX + 'runs') || '{}');

        if (runs[`${params.runID}`]) {
            runs[`${params.runID}`].choices = runs[`${params.runID}`].choices.map((c: { key: string, value: number }) => {
                if (c.key !== params.key) return c;

                return {
                    ...c,
                    value: params.value
                }
            });
        }

        localStorage.setItem(LS_PREFIX + 'runs', JSON.stringify(runs));

        return Promise.resolve(runs[`${params.runID}`]);
    }, []);
}

function useSaveRulesRequest() {

    return useCallback((params: { runID: number, rule1: string, rule2: string, rule3: string }) => {


        const runs = JSON.parse(localStorage.getItem(LS_PREFIX + 'runs') || '{}');

        if (runs[`${params.runID}`]) {
            runs[`${params.runID}`].rules = [params.rule1, params.rule2, params.rule3];
        }

        localStorage.setItem(LS_PREFIX + 'runs', JSON.stringify(runs));

        return Promise.resolve(runs[`${params.runID}`]);
    }, []);

}

function useFinishRunRequest() {

    return useCallback((params: { runID: number }) => {

        const user = JSON.parse(localStorage.getItem(LS_PREFIX + 'user') || 'null');

        if (!user.firstRun) {
            user.firstRun = params.runID
        } else {
            user.lastRun = params.runID
        }

        user.activeRun = null;


        localStorage.setItem(LS_PREFIX + 'user', JSON.stringify(user));

        return Promise.resolve(user);
    }, [])
}

export type ClassInfo = {
    id: string,
    name: string
}

function useGetClassesListRequest(): () => Promise<ClassInfo[]> {

    return useCallback(() => {

        return Promise.resolve([
            { "id": "demo-1", "name": "Demonstracyjna grupa 1." },
            { "id": "demo-2", "name": "Demonstracyjna grupa 2." }
        ]);

    }, []);
}

function useGetClassListRequest(id: string) {

    return useCallback(() => {

        switch (id) {
            case 'demo-1':
                return Promise.resolve([
                    {
                        id: 'demo-1-01',
                        name: 'Konstancja Makowska',
                        firstRun: "0",
                        lastRun: null,
                    },
                    {
                        id: 'demo-1-02',
                        name: 'Alan Malinowski',
                        firstRun: "0",
                        lastRun: "0",
                    },
                    {
                        id: 'demo-1-03',
                        name: 'Nikola Makowska',
                        firstRun: "0",
                        lastRun: null,
                    },
                    {
                        id: 'demo-1-04',
                        name: 'Bogusława Kołodziej',
                        firstRun: null,
                        lastRun: null,
                    },
                    {
                        id: 'demo-1-05',
                        name: 'Antonina Mróz',
                        firstRun: "0",
                        lastRun: null,
                    },
                    {
                        id: 'demo-1-06',
                        name: 'Roman Malinowski',
                        firstRun: "0",
                        lastRun: "0",
                    },
                    {
                        id: 'demo-1-07',
                        name: 'Lucjan Mróz',
                        firstRun: null,
                        lastRun: null,
                    }
                ])

            case 'demo-2':

                return Promise.resolve([
                    {
                        id: 'demo-2-01',
                        name: 'Mieszko Sokołowski',
                        firstRun: null,
                        lastRun: null,
                    },
                    {
                        id: 'demo-2-02',
                        name: 'Przemysław Rutkowski',
                        firstRun: "0",
                        lastRun: null,
                    },
                    {
                        id: 'demo-2-03',
                        name: 'Korneliusz Jankowski',
                        firstRun: "0",
                        lastRun: null,
                    },
                    {
                        id: 'demo-2-04',
                        name: 'Daria Szczepańska',
                        firstRun: null,
                        lastRun: null,
                    },
                    {
                        id: 'demo-2-05',
                        name: 'Mirosław Kucharski',
                        firstRun: null,
                        lastRun: null,
                    }
                ])

            default:
                return Promise.resolve([]);
        }
    }, [id]);
}


function useRulesListRequest(id: string) {

    return useCallback(() => {

        return Promise.resolve([
            {
                user: 'Daniela Zalewska',
                rule: 'Zaczynamy punktualnie, a o ewentualnych spóźnieniach informujemy wszystkich członków zespołu wcześniej.'
            },
            {
                user: 'Cezary Ostrowski',
                rule: 'O ile to możliwe, przez cały czas trwania spotkania mamy włączoną kamerkę, a mikrofon tylko wtedy, gdy chcemy coś powiedzieć.'
            },
            {
                user: 'Angelika Urbańska',
                rule: 'Sprawy towarzyskie i rozmowy dotyczące innych przedmiotów i projektów omawiamy indywidualnie, a nie w czasie wspólnych spotkań.'
            }
        ]);
    }, [id]);

}

function useClassRaportRequest(id: string) {

    return useCallback(() => {

        return Promise.resolve([
            {
                "id": "pon1530",
                "globalVotes": 10059,
                "classVotes": 59,
                "actions": [{
                    "value": 1,
                    "globalVotes": 3340,
                    "classVotes": 7
                },
                {
                    "value": 2,
                    "globalVotes": 3350,
                    "classVotes": 16
                },
                {
                    "value": 3,
                    "globalVotes": 3343,
                    "classVotes": 10
                }]
            },
            {
                "id": "wt1000",
                "globalVotes": 10059,
                "classVotes": 59,
                "actions": [{
                    "value": 1,
                    "globalVotes": 3336,
                    "classVotes": 6
                },
                {
                    "value": 2,
                    "globalVotes": 3370,
                    "classVotes": 17
                },
                {
                    "value": 3,
                    "globalVotes": 3326,
                    "classVotes": 9
                }]
            },
            {
                "id": "wt1659",
                "globalVotes": 10059,
                "classVotes": 59,
                "actions": [
                    { "value": 1, "globalVotes": 3375, "classVotes": 8 },
                    { "value": 2, "globalVotes": 3344, "classVotes": 15 },
                    { "value": 3, "globalVotes": 3310, "classVotes": 6 }]
            },
            {
                "id": "sr1400",
                "globalVotes": 10059,
                "classVotes": 59,
                "actions": [
                    { "value": 1, "globalVotes": 3341, "classVotes": 9 },
                    { "value": 2, "globalVotes": 3294, "classVotes": 13 },
                    { "value": 3, "globalVotes": 3392, "classVotes": 5 }
                ]
            }, {
                "id": "pt1600",
                "globalVotes": 10059,
                "classVotes": 59,
                "actions": [
                    { "value": 1, "globalVotes": 3428, "classVotes": 6 },
                    { "value": 2, "globalVotes": 3335, "classVotes": 14 },
                    { "value": 3, "globalVotes": 3261, "classVotes": 4 }
                ]
            }, {
                "id": "sr1800",
                "globalVotes": 10059,
                "classVotes": 59,
                "actions": [
                    { "value": 1, "globalVotes": 3369, "classVotes": 4 },
                    { "value": 2, "globalVotes": 3344, "classVotes": 8 },
                    { "value": 3, "globalVotes": 3312, "classVotes": 13 }
                ]
            },
            {
                "id": "pt1630",
                "globalVotes": 10059,
                "classVotes": 59,
                "actions": [
                    { "value": 1, "globalVotes": 3335, "classVotes": 6 },
                    { "value": 2, "globalVotes": 3394, "classVotes": 10 },
                    { "value": 3, "globalVotes": 3294, "classVotes": 7 }
                ]
            },
            {
                "id": "cz1530",
                "globalVotes": 10059,
                "classVotes": 59,
                "actions": [
                    { "value": 1, "globalVotes": 3324, "classVotes": 7 },
                    { "value": 2, "globalVotes": 3348, "classVotes": 9 },
                    { "value": 3, "globalVotes": 3352, "classVotes": 8 }
                ]
            }, {
                "id": "cz2000",
                "globalVotes": 10059,
                "classVotes": 59,
                "actions": [
                    { "value": 1, "globalVotes": 3338, "classVotes": 4 },
                    { "value": 2, "globalVotes": 3313, "classVotes": 8 },
                    { "value": 3, "globalVotes": 3373, "classVotes": 12 }
                ]
            }, {
                "id": "pt0800",
                "globalVotes": 10059,
                "classVotes": 59,
                "actions": [
                    { "value": 1, "globalVotes": 3359, "classVotes": 6 },
                    { "value": 2, "globalVotes": 3319, "classVotes": 10 },
                    { "value": 3, "globalVotes": 3346, "classVotes": 8 }
                ]
            }, {
                "id": "sb1100",
                "globalVotes": 10059,
                "classVotes": 59,
                "actions": [
                    { "value": 1, "globalVotes": 3294, "classVotes": 3 },
                    { "value": 2, "globalVotes": 3317, "classVotes": 12 },
                    { "value": 3, "globalVotes": 3411, "classVotes": 7 }
                ]
            }, {
                "id": "pn0855",
                "globalVotes": 10059,
                "classVotes": 59,
                "actions": [
                    { "value": 1, "globalVotes": 3355, "classVotes": 4 },
                    { "value": 2, "globalVotes": 3335, "classVotes": 15 },
                    { "value": 3, "globalVotes": 3333, "classVotes": 4 }
                ]
            }
        ])
    }, [id]);

}


const Network = {
    getConfig,
    getGame
}

export default Network;


const [
    NetworkProvider,
] = createAxiosProxider();

function useGetUserRequest() {

    return useCallback(() => {

        try {
            const user = JSON.parse(localStorage.getItem(LS_PREFIX + 'user') || '');

            return Promise.resolve({
                id: user.id || '',
                firstRun: user.firstRun || null,
                lastRun: user.lastRun || null,
                activeRun: user.activeRun || null,
                activeStep: user.activeStep || null,
                role: user.role || 'player'
            })

        } catch (e) {
            throw new Error();
        }
    }, [])
}

export {
    NetworkProvider,
    useGetUserRequest,
    useNewGameRunRequest,
    useGetGameRunRequest,
    useUpdateActiveStepRequest,
    useLoginRequest,
    useLogoutRequest,
    useRegisterChoiceRequest,
    useSaveRulesRequest,
    useFinishRunRequest,
    useGetClassListRequest,
    useRulesListRequest,
    useClassRaportRequest,
    useGetClassesListRequest
}
