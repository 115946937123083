import { useQuery } from "react-query";
import { useGetClassesListRequest } from "../../network";

export default function useClasses() {
    const request = useGetClassesListRequest();

    const {data, isLoading, error} = useQuery('get-classes-list', request, {
        staleTime: Infinity,
    });

    return {
        classesList: data || [],
        isLoading,
        error
    }
}
