import { useCallback, useEffect, useRef } from 'react';
import './style.scss';

type Props = {
    video: string,
    image: string
}

const StepVideo: React.FC<Props> = ({video, image}) => {

    const player = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (player.current) {
            player.current.play();
        }
    }, [player]);

    const play = useCallback(() => {
        player.current?.play();
    }, []);

    useEffect(() => {
        if (player.current) {
            const p = player.current;

            p.addEventListener('click', play);

            return () => {
                p.removeEventListener('click', play);
            }
        }
    }, [play])

    return <div className="step-video">
        <video src={video} poster={image} autoPlay={true} ref={player} playsInline={true}></video>
    </div>
}

export default StepVideo;
