import { useCallback } from "react";
import { useMutation } from "react-query";
import { USER_ACTION, USER_ROLE, useUserModuleDispatch } from "..";
import { useLoginRequest } from "../../network";

export default function useLogin() {

    const dispatch = useUserModuleDispatch();

    const request = useLoginRequest();

    const { mutateAsync } = useMutation(request,);

    return useCallback(async (id: string, role: USER_ROLE) => {
        await mutateAsync({ id, role });

        dispatch({
            type: USER_ACTION.LOGIN,
            payload: {
                id,
                role
            }
        });
    }, [dispatch, mutateAsync]);
}
