import { useQuery } from "react-query"
import { useRulesListRequest } from "../../network"

export default function useRulesList(id: string) {

    const request = useRulesListRequest(id);

    const { isFetching, data, error } = useQuery(['get-rules'], request, {
        staleTime: Infinity
    });


    return {
        isLoading: isFetching,
        data,
        error
    }
}
