import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import React from "react";
import AzureLogin from "../../components/azure-login";

type Props = {}

const AzureAuthorization: React.FC<Props> = ({ children }) => {

    return <>
        <AuthenticatedTemplate>
            {children}
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
            <AzureLogin />
        </UnauthenticatedTemplate>
    </>
}

export default AzureAuthorization;
