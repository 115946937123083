import { AuthenticatedTemplate } from "@azure/msal-react"
import React from "react"

type Props = {

}

const AzureForLogged: React.FC<Props> = ({children}) => {
    return <AuthenticatedTemplate>
        {children}
    </AuthenticatedTemplate>
}

export default AzureForLogged;
