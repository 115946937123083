import { useMutation } from "react-query";
import { useNewGameRunRequest } from "../../network";
import { USER_ACTION, useUserModuleDispatch } from "../../user";
import useSetRunID from "./use-set-run-id";

export default function useNewGameRun() {

    const userDispatch = useUserModuleDispatch();
    const setRunID = useSetRunID();

    const newGameRun = useNewGameRunRequest();

    const {mutate, error, isLoading} = useMutation(newGameRun, {
        onSuccess(data) {
            setRunID(data.runID);
            userDispatch({
                type: USER_ACTION.FETCH_SUCCESS,
                payload: {
                    ...data.user
                }
            })
        }
    })

    return {
        newGameRun: mutate,
        error,
        isLoading
     };
}
