import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { GameStep, StepAction } from "../../modules/game";

import './style.scss';

type Props = {
    step: GameStep,
    onStepComplete: (action: StepAction) => void,
    disableButton?: boolean
}

const letters = ['A', 'B', 'C'];

const StepDefault: React.FC<Props> = ({ step, onStepComplete, disableButton }) => {

    const formMethods = useForm();
    const { watch, handleSubmit, setValue } = formMethods;
    const headerRef = useRef<HTMLHeadingElement>(null);

    const actions = useMemo(() => (step.actions || []), [step]);

    const actionIndex = watch('actionIndex', null);
    const isNextAvaiable = (actions.length === 1 || actionIndex != null) && !disableButton;

    const onSubmit = useCallback((data: any) => {

        if (actions.length === 1) {
            onStepComplete(actions[0]);
        }

        const action = actions[data.actionIndex];
        if (action) {
            onStepComplete(action);
        }
    }, [actions, onStepComplete]);

    useEffect(() => {
        setValue('actionIndex', null);
    }, [step, setValue])

    useEffect(() => {
        if (headerRef.current) {
            headerRef.current.focus();
        }
    }, [step])

    return <div className="step-default">
        <header>
            <h1 className="step-heading" ref={headerRef} tabIndex={-1}>{step.inGameTime}</h1>
        </header>
        <div className="step-text" tabIndex={-1}>
            <p dangerouslySetInnerHTML={{ __html: step.text }}></p>
        </div>
        <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="task-actions" role='group' aria-label="Możliwe akcje">
                    {
                        actions.length > 1 && actions.map((a, index) => {
                            return <div className="action" key={`${step.id}${index}`}>
                                <Action index={index}>
                                    <p>{a.text}</p>
                                </Action>
                            </div>
                        })
                    }
                </div>
                <button className="button" disabled={!isNextAvaiable} aria-disabled={!isNextAvaiable}>Dalej</button>
            </form>
        </FormProvider>
    </div>
}

export default StepDefault;

const ConnectForm: React.FC<any> = ({ children }) => {
    const methods = useFormContext();

    return children({ ...methods });
};


const Action: React.FC<{
    index: number
}> = ({ children, index }) => {
    const { setValue } = useFormContext();

    const inputRef = useRef<HTMLInputElement>(null);

    const ffLabelClickFix = useCallback(() => {
        if (inputRef.current) {
            setValue('actionIndex', index);
        }
    }, [setValue, index])

    return <ConnectForm>
        {({ register }: any) => <>
            <input type="radio" name="actionIndex" value={index} {...register('actionIndex')} id={`action-${index}`} ref={inputRef} />
            <label className="action-radio" htmlFor={`action-${index}`} onMouseDown={ffLabelClickFix}>
                <span aria-hidden="true">{letters[index]}</span>
                {children}
            </label>
        </>}
    </ConnectForm>
}
