import { useQuery } from "react-query"
import { useGetClassListRequest } from "../../network"

export default function useClassList(id: string) {

    const request = useGetClassListRequest(id);

    const {isFetching, data, error} = useQuery(['get-class', id], request, {
        staleTime: Infinity
    });


    return {
        isLoading: isFetching,
        data,
        error
    }
}
