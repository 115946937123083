import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { GAME_RUN_ACTION, useGameRunModuleDispatch } from "..";

export default function useSetRunID() {

    const queryClient = useQueryClient();
    const dispatch = useGameRunModuleDispatch();

    return useCallback((id: number) => {
        queryClient.invalidateQueries(['get-run', id]);

        dispatch({
            type: GAME_RUN_ACTION.SET_ID,
            payload: {
                id
            }
        })

    }, [dispatch, queryClient]);
}
