import { useCallback } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { USER_ROLE } from "../../modules/user";
import useLogin from "../../modules/user/hooks/use-login";

type Props = {

}

const Container = styled.div`
    label {
        display: block;
        margin: 1em;
        text-align: center;

        input, select {
            width: 100%;
        }
    }

    width: 95%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

const DemoLoginForm: React.FC<Props> = () => {

    const {handleSubmit, register} = useForm();
    const login = useLogin();

    const onSubmit = useCallback((data: any) => {
        login(data.userId, data.userRole);
    }, [login]);

    return <Container>
        <h2>Ograniczenia strony demonstracyjnej</h2>
        <ol>
            <li>Przebieg gry zapisywany jest jedynie lokalnie na komputerze uczestnika i jest kasowany po wylogowaniu</li>
            <li>Dane prezentowana w panelu administratora są statyczne i nie zmieniają się w reakcji na akcje usera</li>
        </ol>
        <form onSubmit={handleSubmit(onSubmit)}>

            <div>
                <label>
                    Kim jesteś? <br />
                    <select name="userRole" ref={register}>
                        <option value={USER_ROLE.PLAYER}>Uczeń</option>
                        <option value={USER_ROLE.TEACHER}>Nauczyciel</option>
                    </select>
                </label>
                <label>
                        Podaj swoje ID<br />
                        <input name="userId" ref={register({
                            required: true
                        })}></input>
                </label>
            </div>

            <button type="submit">Wejdź</button>

        </form>
    </Container>
}

export default DemoLoginForm;
