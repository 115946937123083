import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { GameStep, StepAction } from "../../modules/game";
import { useForm } from "react-hook-form";
import useSetRules from "../../modules/game-run/hooks/use-set-rules";

import './style.scss';
import { ErrorMessage } from "@hookform/error-message";

type Props = {
    step: GameStep,
    onStepComplete: (action: StepAction) => void,

}

const StepRules: React.FC<Props> = ({ step, onStepComplete }) => {

    const { register, watch, handleSubmit, formState: { isValid }, errors } = useForm({
        mode: 'onChange',
        criteriaMode: 'all'
    })
    const rules = watch();
    const setRules = useSetRules();

    const headerRef = useRef<HTMLHeadingElement>(null);

    const actions = useMemo(() => (step.actions || []), [step]);

    const onSubmit = useCallback((data: any) => {
        setRules(Object.values(rules));

        if (actions.length === 1) {
            onStepComplete(actions[0]);
        }

        const action = actions[data.actionIndex];
        if (action) {
            onStepComplete(action);
        }
    }, [onStepComplete, rules, setRules, actions]);

    const actionIndex = watch('actionIndex', null);

    const isAnyRule = useMemo(() => {
        return rules['rule-1']?.length > 0 || rules['rule-2']?.length > 0 || rules['rule-3']?.length > 0
    }, [rules]);


    const isNextAvaiable = (actions.length === 1 || actionIndex != null) && isAnyRule && isValid;

    useEffect(() => {
        if (headerRef.current) {
            headerRef.current.focus();
        }
    }, [step])

    return <div className="step-rules">
        <header>
            <h1 className="step-heading" ref={headerRef} tabIndex={0}>{step.inGameTime}</h1>
        </header>
        <div className="step-text">
            <p>{step.text}</p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="textareas">
                <div>
                    <textarea
                        placeholder="Wpisz zasadę 1 tutaj..."
                        name="rule-1"
                        ref={register({
                            maxLength: {
                                value: 255,
                                message: "Zasada musi być krótsza niż 255 znaków!"
                            }
                        })}
                    />
                    <div className="error-container" aria-live="polite">
                        <ErrorMessage errors={errors} name="rule-1" />
                    </div>

                </div>
                <div>
                    <textarea
                        placeholder="Wpisz zasadę 2 tutaj..."
                        name="rule-2"
                        ref={register({
                            maxLength: {
                                value: 255,
                                message: "Zasada musi być krótsza niż 255 znaków!"
                            }
                        })}
                    />
                    <div className="error-container" aria-live="polite">
                        <ErrorMessage errors={errors} name="rule-2" />
                    </div>
                </div>
                <div>
                    <textarea
                        placeholder="Wpisz zasadę 3 tutaj..."
                        name="rule-3"
                        ref={register({
                            maxLength: {
                                value: 255,
                                message: "Zasada musi być krótsza niż 255 znaków!"
                            }
                        })}
                    />
                    <div className="error-container" aria-live="polite">
                        <ErrorMessage errors={errors} name="rule-3" />
                    </div>
                </div>
            </div>

            <button className="button" disabled={!isNextAvaiable}>Dalej</button>
        </form>
    </div>

}

export default StepRules;
