import createStore from "../../utils/react-create-store";
import UserReducer from "./reducer";

export enum USER_ROLE {
    PLAYER = 'player',
    TEACHER = 'teacher',
    ADMIN = 'admin'
}

export type GameRunModuleState = {
    id?: number,
    isLoading: boolean,
    answers: {
        choices: { key: string, value: number }[]
        rules: string[]
    }
}

export enum GAME_RUN_ACTION {
    SET_CHOICE = 'game-run:set-choice',
    SET_RULES = 'game-run:set-rules',
    SET_ID = 'game-run:set-id',
    FETCH_SUCCESS = 'game-run:fetch-success',
    RESET = 'game-run:reset'
}

export type GameRunModuleAction = {
    type: GAME_RUN_ACTION,
    payload: any
}

export const initGameRunState: GameRunModuleState = {
    isLoading: false,
    answers: {
        choices: [],
        rules: []
    }
}


const [
    GameRunModuleProvider,
    useGameRunModuleDispatch,
    useGameRunModuleState
] = createStore(UserReducer, initGameRunState, 'netykieta-dev-game-run');

export {
    GameRunModuleProvider,
    useGameRunModuleDispatch,
    useGameRunModuleState
}
