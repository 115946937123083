import './style.scss';

import syrenkaLogo from '../../assets/logos/logo-syrenka.png';
import gdLogo from '../../assets/logos/logo-gd.png';

export default function PageFooter() {
    return <footer className="page-footer">
        <div className="wrapper">
            <div className="wawa">
                <h2 className="heading-3">Organizator:</h2>
                <img src={syrenkaLogo} alt="Zakochaj się w warszawie" />
            </div>
            <div className="gde">
                <h2 className="heading-3">Wykonawca:</h2>
                <a href="https://gdevents.pl/"><img src={gdLogo} alt="GD Events" /></a>
            </div>
        </div>
    </footer >
}
