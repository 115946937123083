import './style.scss';
import useFontSizeMode from '../../modules/main/hooks/use-font-size-mode';
import { getHomeRoute } from '../../routes/routes';
import React from 'react';
import { Link, Route } from 'react-router-dom';
import LoggedInfo from '../logged-info';

type Props = {

}

const AccessibilityBar: React.FC<Props> = () => {

    const { setFontSize } = useFontSizeMode();

    return <aside className="accessibility-bar">
        <div className="bar-container">
            <div className="menus-container">
                <div className="font-size-menu accessibility-menu">
                    <div id="font-size-menu-label" className="accessibility-menu-label">Czcionka:</div>
                    <div className="buttons" role="group" aria-labelledby="font-size-menu-label">
                        <button onClick={() => { setFontSize(1) }} className="font-button font-100" aria-label="Normalna wielkość tekstu">A<sup></sup></button>
                        <button onClick={() => { setFontSize(1.5) }} className="font-button font-150" aria-label="Wielkość tekstu 150%">A<sup>+</sup></button>
                        <button onClick={() => { setFontSize(2) }} className="font-button font-200" aria-label="Wielkość tekstu 200%">A<sup>++</sup></button>
                    </div>
                </div>
            </div>
            <div className="page-controls">
                <Route render={({ location }) => location.pathname === getHomeRoute()
                    ? <></>
                    : <nav>
                        <Link className="button btn-inverted btn-small" to={getHomeRoute()}>Wróć do strony głównej</Link>
                    </nav>
                } />

                <LoggedInfo />
            </div>
        </div>
    </aside>
}

export default AccessibilityBar;
