import { useEffect, useRef } from "react";
import { getHomeRoute } from "../../routes/routes"

type Props = {

}

const ErrorInfo: React.FC<Props> = () => {

    const link = useRef<HTMLAnchorElement>(null);

    useEffect(() => {
        link.current?.focus();
    }, [])

    return <div className="error-dialog" role="alert" aria-labelledby="error-header">
        <h2 className="heading-error" id="error-header">Wystąpił błąd</h2>
        <div className="icon"></div>
        <a className="button" ref={link} href={getHomeRoute()}>Powrót do strony głównej</a>
    </div>
}

export default ErrorInfo;
