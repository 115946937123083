import { useCallback } from "react";
import { useMutation } from "react-query";
import { GAME_RUN_ACTION, useGameRunModuleDispatch } from "..";
import { useSaveRulesRequest } from "../../network";
import useGameActiveRun from "./use-game-active-run";

export default function useSetRules() {
    const dispatch = useGameRunModuleDispatch();
    const {gameRun} = useGameActiveRun();

    const request = useSaveRulesRequest()

    const {mutate} = useMutation(request);

    return useCallback((rules: string[]) => {
        if (gameRun.id) {
            mutate({
                runID: gameRun.id,
                rule1: rules[0] || '',
                rule2: rules[1] || '',
                rule3: rules[2] || ''
            });
        }

        dispatch({
            type: GAME_RUN_ACTION.SET_RULES,
            payload: {
                rules: rules.slice(0,3)
            }
        })
    }, [dispatch, gameRun.id, mutate])
}
