import { AxiosRequestConfig } from "axios";
import React, { Context, useCallback, useRef } from "react";
import { NetworkProvider } from "../network";

type Output = [
    React.FC<{}>,
    () => ((v: string) => void)
]

function createAzureProvider(): Output {

    const azureContext: Context<{
        setAccessToken: (value: string) => void
    }> = React.createContext({ setAccessToken: _ => { } });

    const AzureProvider: React.FC = ({ children }) => {
        const accessToken = useRef({ value: '' });

        const axiosInterceptor = useCallback((config: AxiosRequestConfig) => {
            if (accessToken.current.value) {
                return {
                    ...config,
                    headers: {
                        ...config.headers,
                        "Authorization": `Bearer: ${accessToken.current.value}`
                    }
                }
            }

            return config;
        }, []);

        const setAccessToken = useCallback((value: string) => {
            accessToken.current.value = value;
        }, []);

        const value = {
            setAccessToken
        }

        return <>
            <azureContext.Provider value={value}>
                <NetworkProvider interceptor={axiosInterceptor}>
                    {children}
                </NetworkProvider>
            </azureContext.Provider>
        </>
    }

    function useSetAccessToken() {
        return React.useContext(azureContext).setAccessToken;
    }

    return [AzureProvider, useSetAccessToken]
}

export default createAzureProvider;
