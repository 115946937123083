import useUser from "../../modules/user/hooks/use-user"
import ForLogged from "../for-logged"
import LogoutButton from "../logout-button";
import './style.scss';

type Props = {

}

const LoggedInfo: React.FC<Props> = () => {

    const {user, isLoading} = useUser();

    return <ForLogged>
        {!isLoading && <div className="logged-info">
            <span className="user-name" aria-label={`Zalogowany jako ${user.name}`}>{user.name}</span>
            <LogoutButton></LogoutButton>
        </div>}
    </ForLogged>
}

export default LoggedInfo;
