import ClassesList from "../../../components/classes-list";
import './style.scss';

export default function TeacherPanelPage() {

    return <main className="teacher-panel-page">
        <div className="summary-top-gfx"></div>
        <h1 className="heading-1">Panel nauczyciela</h1>
        <div className="content">
            <ClassesList />
        </div>

    </main>
}
