import { useCallback } from "react";
import { useHistory } from "react-router";
import { getGameEndRoute } from "../../../routes/routes";
import { GameStep, StepAction } from "../../game";
import useMoveToStep from "./use-move-to-step";
import useUserChoice from "../../game-run/hooks/use-set-user-choice";
import { useMutation } from "react-query";
import { useRegisterChoiceRequest } from "../../network";
import useGameActiveRun from "../../game-run/hooks/use-game-active-run";

export default function useUserMakeChoice(step: GameStep | null) {
    const setUserChoice = useUserChoice();
    const moveToStep = useMoveToStep();
    const { gameRun } = useGameActiveRun();
    const history = useHistory();

    const reristerChoiceRequest = useRegisterChoiceRequest();

    const { mutateAsync: register, isLoading: isRegisterLoading} = useMutation(reristerChoiceRequest);

    const userMakeChoice = useCallback(async (action: StepAction) => {

        if (!step) {
            return Promise.resolve(null);
        }

        const nextStep = action.nextStep || step.nextStepId;

        if (gameRun.id && action.value) {
            register({
                runID: gameRun.id,
                key: step.id,
                value: action.value
            });
        }

        if (action.value !== null && action.value !== undefined) {
            setUserChoice(step.id, action.value);
        }

        if (nextStep) {
            moveToStep(nextStep);
            return nextStep;
        } else {
            history.push(getGameEndRoute());
            return null;
        }

    }, [step, history, moveToStep, setUserChoice, gameRun, register]);

    return {
        userMakeChoice,
        isLoading: isRegisterLoading,
    }
}
