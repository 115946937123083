import { useMemo } from "react";
import loaderImage from '../../assets/icons/loader.svg';
import contrastLoaderImage from '../../assets/icons/contrast/loader.svg';
import { useMainModuleState } from "../../modules/main";

const Loader: React.FC<{}> = () => {
    const {isHighContrastMode} = useMainModuleState();

    const style: React.CSSProperties = useMemo(() => {
        return {
            position: 'absolute',
            width: '100%',
            minHeight: '20vh',
            top: 0,
            bottom: 0,

            backgroundImage: `url(${isHighContrastMode ? contrastLoaderImage : loaderImage})`,
            backgroundSize: 'auto 100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 50%',
        }
    }, [isHighContrastMode])

    return <div  style={style} aria-busy="true" role="alert" aria-label="Ładowanie danych">
    </div>
}

export default Loader;
