import { useEffect } from "react";
import { useMutation } from "react-query";
import { useUpdateActiveStepRequest } from "../../network";

export default function useUpdateActiveStep(stepID: string | null) {

    const request = useUpdateActiveStepRequest();

    const {mutate} = useMutation(request)

    useEffect(() => {
        if (stepID) {
            mutate(stepID);
        }
    }, [mutate, stepID])
}
